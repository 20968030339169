import { render, staticRenderFns } from "./teaCard.vue?vue&type=template&id=8f4524fa&scoped=true"
import script from "./teaCard.vue?vue&type=script&lang=js"
export * from "./teaCard.vue?vue&type=script&lang=js"
import style0 from "./teaCard.vue?vue&type=style&index=0&id=8f4524fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f4524fa",
  null
  
)

export default component.exports